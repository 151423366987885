import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useSubscribeByEmailMutation } from "../../store/services/profileService";
import { useGetCategoryQuery } from "../../store/services/categoryService";

const Footer = () => {
  const {
    isLoading: categoriesLoading,
    isFetching: categoriesFatching,
    data: categoriesList,
  } = useGetCategoryQuery();
  const [openDetail, stOpenDetail] = useState(false);
  const [subscribeByEmailData, response] = useSubscribeByEmailMutation();

  const [state, setState] = useState({
    email: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const subscribeByEmailFunction = (e) => {
    e.preventDefault();
    subscribeByEmailData(state);
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      // window?.location?.reload();
    }
  }, [response.isSuccess]);

  return (
    <div className="footer-comp flex bg-black flex-col w-full">
      <div className="subscribe-section flex items-center">
        <div className="sub-block wrap wrapWidth flex items-center">
          <div className="left-side flex items-center">
            <p className="text-base">
              Sign-up for our mailing list and we’ll send you all kinds of love!
            </p>
          </div>
          <form
            onSubmit={subscribeByEmailFunction}
            className="right-side flex items-center"
          >
            <div className="input-field flex items-center">
              <input
                type="email"
                name="email"
                required
                placeholder="Email Address"
                className="txt-mail cleanbtn"
                value={state?.email}
                onChange={handleInputs}
              />

              <input
                type="submit"
                className="btn-subscribe button"
                value={response.isLoading ? "Loading..." : "SUBSCRIBE"}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="wrap wrapWidth flex flex-col">
        <div className="footer-top-part flex">
          <div className="top-part-shop-sec flex flex-col">
            <h1 className="sec-title">SHOP</h1>
            <div className="list flex flex-col">
              {/* <div href="/" className="list-itm">
                All Products
              </div> */}
              {categoriesList?.data?.map((item, index) => (
                <Link
                  key={index}
                  to={`/collections/shop/${item.id}`}
                  className="list-itm"
                >
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="top-part-help-sec flex flex-col">
            <h1 className="sec-title">HELP</h1>
            <div className="list flex flex-col">
              <Link to="/not-found" className="list-itm">
                FAQs
              </Link>
            </div>
          </div>
          <div className="top-part-contact-with-sec flex  flex-col">
            <div className="cws-sec flex flex-col">
              <h1 className="sec-title">CONNECT WITH US</h1>
              <div className="social flex items-center">
                <div className="icon flex items-center justify-center">
                  <i className="fa-brands fa-instagram"></i>
                </div>
                <div className="icon flex items-center justify-center">
                  <i className="fa-brands fa-facebook-f"></i>
                </div>
                <div className="icon flex items-center justify-center">
                  <i className="fa-brands fa-twitter"></i>
                </div>
                <div className="icon flex items-center justify-center">
                  <i className="fa-brands fa-twitter"></i>
                </div>
                <div className="icon flex items-center justify-center">
                  <i className="fa-brands fa-tiktok"></i>
                </div>
              </div>
            </div>
            <div className="we-accept-sec flex flex-col">
              <h1 className="sec-title">WE ACCEPT</h1>
              <div className="cards flex items-center">
                <i className="fa-brands fa-cc-visa"></i>
                <i className="fa-brands fa-cc-mastercard"></i>
                <i className="fa-brands fa-cc-discover"></i>
                <i className="fa-brands fa-cc-amex"></i>
              </div>
            </div>
          </div>
          <div className="top-part-contact-us-sec flex flex-col">
            <h1 className="sec-title">CONTACT US</h1>
            <div className="cs-item flex items-center">
              <div className="icon flex items-center justify-center">
                <i className="fa-solid fa-phone"></i>
              </div>
              <h1 className="lbl">+92-24-7003964</h1>
            </div>{" "}
            <div className="cs-item flex items-center">
              <div className="icon flex items-center justify-center">
                <i className="fa-solid fa-at"></i>
              </div>
              <h1 className="lbl">info@zeezsoft.com</h1>
            </div>
            <button className="cs-btn button">CONTACT US</button>
          </div>
        </div>
        <div className="certificate-image flex items-center justify-center">
          <img src="./images/certificate.png" className="c-image" />
        </div>
        <div className="footer-center-part flex flex-col">
          <div className="desc-dropdown text-center">
            <p className="desc-d text-white">
              ∆9-tetrahydroncannabinol (THC) in accordance with the regulations
              set forth in the 2018 Farm Bill.
            </p>
            <p className="desc-d my-3 text-white">
              *FDA Disclaimer - The statements made regarding these products
              have not been evaluated by the Food and Drug Administration. The
              efficacy of these products has not been confirmed by FDA-approved
              research. These products are not intended to diagnose, treat, cure
              or prevent any disease.
            </p>
            {openDetail ? (
              <p className="desc-d my-3 text-white">
                **TRĒ House products that are marketed as ND-THC
                (non-detectable) may contain trace amounts of THC that nearly
                undetectable by scientific laboratory testing standards. TRĒ
                House™ assumes no responsibility for and expressly disclaims all
                legal issues resulting from your purchase or use of the TRĒ
                House™ products. You agree that you will be solely responsible
                for your decision to purchase TRĒ House™ products and
                determining compliance with the local rules or regulations in
                your applicable jurisdiction. Information presented on this
                website is for informational purposes only, and is not legal
                advice. All references to ‘Organic’ within the TREhouse.com
                website are in reference to the Organic Farming methods and
                techniques used by TRĒ House partner farmers and do not imply
                that TRĒ House products are Certified Organic. All information
                presented here is not meant as a substitute for or alternative
                to information from healthcare practitioners. Consult with your
                healthcare professional about potential interactions or other
                possible complications before using any product. It is
                especially important for those who are pregnant, nursing,
                chronically ill, elderly or under the age of 21 to discuss the
                use of these products with a healthcare professional prior to
                using these products. You must be at least 21 years of age, or
                the applicable age in your jurisdiction, to visit this website
                and/or purchase TRĒ House™ products. The information on our
                website is intended to provide general information regarding our
                products and is not to be construed as medical advice or
                instruction. TRĒ House™ is not responsible for information
                contained in customer testimonials or product reviews. These
                statements are the opinion of the customer, not of TRĒ House™ or
                any of its affiliates. TRĒ House™ products and information are
                provided on this website under the Terms & Conditions and
                Privacy Policy.
              </p>
            ) : null}
            <div
              className="icon-down flex items-center justify-center cursor-pointer"
              onClick={(e) => stOpenDetail(!openDetail)}
            >
              {openDetail ? (
                <i className="fa-solid fa-chevron-up text-white" />
              ) : (
                <i className="fa-solid fa-chevron-down text-white" />
              )}
            </div>
          </div>
          <div className="warning-sec flex items-start justify-center">
            <i className="warning-icon fa-solid fa-triangle-exclamation"></i>
            <p className="text-center text-white">
              WARNING: Smoking or consuming cannabis or hemp products can expose
              you to chemicals, including hemp smoke, which are known to the
              State of California to cause cancer; methanol, which is known to
              the State of California to cause birth defects or other
              reproductive harm; and ∆9- tetrahydroncannabinol, which is known
              to the State of California to cause reproductive harm. For more
              information go to www.P65Warnings.ca.gov.
            </p>
          </div>
        </div>
        <div className="footer-bottom-part flex text-white">
          <div className="bottom-part-left flex items-start justify-center">
            <Link to="/not-found" className="bp-title">
              Privacy Policy
            </Link>
          </div>
          <div className="bottom-part-center flex flex-col items-center">
            <h1 className="bp-title mb-3">
              Do Not Sell My Personal Information
            </h1>
            <h1 className="bp-title">© 2023 TRĒ House. All rights reserved.</h1>
          </div>
          <div className="bottom-part-right flex items-start justify-center">
            <Link to="/not-found" className="bp-title">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
