import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
} from "../../store/services/profileService";

const ProfileUpdate = () => {
  const {
    data: profileData,
    isFetching: profileDataFetching,
    isLoading: profileDataLoading,
    isError: profileDataError,
  } = useGetUserDataQuery();

  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    city: "",
    country: "",
    address: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!profileDataError) {
      setState(profileData?.data);
    }
  }, [profileData?.data]);

  const [profileUpdateData, response] = useUpdateUserDataMutation();
  //   console.log("update profile response...", response);

  const userProfileUpdateFunction = (e) => {
    e.preventDefault();
    profileUpdateData(state);
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(response?.data?.message);
    }
  }, [response.isSuccess]);

  //   console.log("state data...", state);
  return (
    <div className="flex w-full min-h-screen">
      <form
        onSubmit={userProfileUpdateFunction}
        className="form flex flex-col w-full mt-10"
      >
        <div className="input-field flex flex-col w-full mb-3">
          <label
            htmlFor="first_name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            First Name
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="first_name"
              id="first_name"
              required
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
              placeholder="First Name"
              aria-describedby="firstname-description"
              value={state?.first_name}
              onChange={handleInputs}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description"></p>
        </div>
        <div className="input-field flex flex-col w-full mb-3">
          <label
            htmlFor="last_name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Last Name
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="last_name"
              id="last_name"
              required
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
              placeholder="Last Name"
              aria-describedby="lastname-description"
              value={state?.last_name}
              onChange={handleInputs}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description"></p>
        </div>
        <div className="input-field flex flex-col w-full mb-3">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email
          </label>
          <div className="mt-2">
            <input
              type="email"
              name="email"
              id="email"
              required
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
              placeholder="you@example.com"
              aria-describedby="email-description"
              value={state?.email}
              onChange={handleInputs}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description"></p>
        </div>
        <div className="input-field flex flex-col w-full mb-3">
          <label
            htmlFor="phone"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Phone Number
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="phone"
              id="phone"
              required
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
              placeholder="+1 (555) 987-6543"
              aria-describedby="phone-description"
              value={state?.phone}
              onChange={handleInputs}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description"></p>
        </div>
        <div className="input-field flex flex-col w-full mb-3">
          <label
            htmlFor="city"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            City
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="city"
              id="city"
              required
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
              placeholder="City"
              aria-describedby="city-description"
              value={state?.city}
              onChange={handleInputs}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description"></p>
        </div>
        <div className="input-field flex flex-col w-full mb-3">
          <label
            htmlFor="country"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Country
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="country"
              id="country"
              required
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
              placeholder="Country"
              aria-describedby="country-description"
              value={state?.country}
              onChange={handleInputs}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description"></p>
        </div>
        <div className="input-field flex flex-col w-full mb-3">
          <label
            htmlFor="address"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Address
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="address"
              id="address"
              required
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
              placeholder="Address..."
              aria-describedby="address-description"
              value={state?.address}
              onChange={handleInputs}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description"></p>
        </div>

        <input
          type="submit"
          className="btn-save button w-max mb-10 rounded-md"
          value={response.isLoading ? "Loading..." : "SAVE CHANGES"}
        />
      </form>
    </div>
  );
};

export default ProfileUpdate;
