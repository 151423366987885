import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setOpenSidebar, setOrderData } from "../store/reducers/globalReducer";

const ProductCard = ({ item, index }) => {
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_API_URL;
  const { orderData } = useSelector((state) => state.globalReducer);
  const getStarIcons = (rated) => {
    const stars = [];
    const rating = Math.floor(rated); // Get the integer part of the rating
    const isHalfStar = rated % 1 !== 0; // Check if there is a half star

    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        // Render a filled star icon
        stars.push(
          <i key={i} className="fas fa-star text-sm text-yellow-400"></i>
        );
      } else if (isHalfStar && i === rating) {
        // Render a half-filled star icon
        stars.push(
          <i
            key={i}
            className="fas fa-star-half-alt text-sm text-yellow-400"
          ></i>
        );
      } else {
        // Render an empty star icon
        stars.push(<i key={i} className="far fa-star text-sm"></i>);
      }
    }
    return stars;
  };
  const updatedProducts = [...orderData?.products];

  const addToCart = ({ item }) => {
    const existingProductIndex = updatedProducts.findIndex(
      (product) => product.pro_id === item.id
    );

    if (existingProductIndex !== -1) {
      // If the product already exists, increase the quantity
      updatedProducts[existingProductIndex] = {
        ...updatedProducts[existingProductIndex],
        pro_qty: updatedProducts[existingProductIndex].pro_qty + 1,
      };
    } else {
      // If the product doesn't exist, add it to the array
      const newProduct = {
        pro_id: item.id,
        pro_name: item.name,
        pro_image: item.image,
        pro_price: item.price,
        pro_qty: 1,
      };
      updatedProducts.push(newProduct);
    }

    // Update the state with the modified 'products' array
    dispatch(setOrderData({ ...orderData, products: updatedProducts }));
    setTimeout(() => {
      dispatch(setOpenSidebar(true));
    }, 500);
  };

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      dispatch(setOpenSidebar(false));
    });
  }, []);

  return (
    <Link
      key={index}
      to={`/products/detail/${item.slug}`}
      className="slider-card flex flex-col justify-between"
    >
      <div className="prd-meta flex flex-col items-center">
        <img src={`${baseUrl}/${item.image}`} className="prd-img" />
        <div className="about-product flex flex-col items-center">
          <div className="prd-review flex items-center">
            <div className="rating-bar mr-3">{getStarIcons(item.rating)}</div>
            <h4 className="number-of-rating">
              {`(${item.totalreview}  Reviews)`}
            </h4>
          </div>
          <h1 className="prd-name">{item.name}</h1>
          <h1 className="prd-price">${item.price}</h1>
        </div>
      </div>
      <div className="action flex items-center justify-center w-full">
        <button
          className="btn-add-cart button"
          onClick={(e) => {
            e.preventDefault();
            addToCart({ item });
          }}
        >
          Add to Cart
        </button>
      </div>
    </Link>
  );
};

export default ProductCard;
