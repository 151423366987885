import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import ReactHtmlParser from "react-html-parser";
import { Link, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Wrapper from "../../routes/Wrapper";
import NewLoader from "../../components/NewLoader";
import DateConverter from "../../components/DateConverter";
import WriteReview from "./WriteReview";
import AskQuestion from "./AskQuestion";
import { MinusSmallIcon, PlusSmallIcon } from "../../assets/icons";
import { useGetProductDetailQuery } from "../../store/services/productService";

import {
  setOpenSidebar,
  setOrderData,
} from "../../store/reducers/globalReducer";

const ProductDetail = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { orderData } = useSelector((state) => state.globalReducer);
  // console.log("what is orderData....", orderData);

  const {
    isLoading: productDetailLoading,
    isFetching: productDetailFetching,
    data: productDetailData,
  } = useGetProductDetailQuery(slug);

  console.log("product detail data", productDetailData);
  const updatedProducts = [...orderData?.products];

  const existingProduct = updatedProducts.find(
    (product) => product.pro_id === productDetailData?.data?.id
  );
  const [qty, setQty] = useState(1);
  const [activeTab, setActiveTab] = useState("description");
  const [activeActionTab, setActiveActionTab] = useState("reviews");
  const tabsList = [
    { lbl: "description", slug: "/" },
    { lbl: "ingredient", slug: "/" },
    { lbl: "faq", slug: "/" },
  ];

  const getStarIcons = (rated) => {
    const stars = [];
    const rating = Math.floor(rated); // Get the integer part of the rating
    const isHalfStar = rated % 1 !== 0; // Check if there is a half star

    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        // Render a filled star icon
        stars.push(
          <i key={i} className="fas fa-star text-lg text-yellow-400" />
        );
      } else if (isHalfStar && i === rating) {
        // Render a half-filled star icon
        stars.push(
          <i key={i} className="fas fa-star-half-alt text-lg text-yellow-400" />
        );
      } else {
        // Render an empty star icon
        stars.push(<i key={i} className="far fa-star text-lg" />);
      }
    }
    return stars;
  };

  const FaqsItems = ({ key, item }) => {
    const [expend, setExpend] = useState(false);
    return (
      <div key={key} className="faq-item flex flex-col">
        <div
          className="item-hdr flex items-center justify-between cursor-pointer"
          onClick={(e) => setExpend(!expend)}
        >
          <h1 className="qustion">{item?.title ? item?.title : ""}</h1>
          <button
            className="icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setExpend(!expend)}
          >
            {expend ? <MinusSmallIcon /> : <PlusSmallIcon />}
          </button>
        </div>
        <p className={`answer ${expend ? "show" : ""}`}>
          {item?.detail ? item?.detail : ""}
        </p>
      </div>
    );
  };

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      dispatch(setOpenSidebar(false));
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setQty(existingProduct ? existingProduct.pro_qty : 1);
  }, [existingProduct]);

  const ReviewItem = ({ key, item }) => {
    return (
      <div className="review-item flex flex-col">
        <div className="rating-bar">{getStarIcons(item.rating)}</div>
        <h1 className="client-name">
          {item.name}. <span className="varied">Verified Buyer</span>
        </h1>
        <h1 className="comment">{item.title}</h1>
        <p className="desc">{item.review}</p>
        <h1 className="date">
          <DateConverter originalDate={item.created_at} />
        </h1>
      </div>
    );
  };

  const QuestionItem = ({ key, item }) => {
    return (
      <div className="question-item flex flex-col">
        <h1 className="client-name">
          <span className="uppercase"> {item.name}.</span>{" "}
          <span className="varied">Verified Buyer</span>
        </h1>
        <h1 className="comment mb-3">Q. {item.title}</h1>
        <p className="desc mb-6">
          <span className=" font-normal text-sm underline">ANSWER . </span>
          <div className="flex items-center">
            <img
              src="/images/logo.png"
              className=" h-12 w-12 rounded-full mt-6 mb-5"
            />
            <h1 className=" text-lg font-medium mt-4 ml-3">Viexotics</h1>
          </div>
          A. {item.ans}
        </p>
        <h1 className="date">
          <DateConverter originalDate={item.created_at} />
        </h1>
      </div>
    );
  };

  const addToCart = ({ item, p_qty }) => {
    const existingProductIndex = updatedProducts.findIndex(
      (product) => product.pro_id === item.id
    );

    if (existingProductIndex !== -1) {
      // If the product already exists, increase the quantity
      updatedProducts[existingProductIndex] = {
        ...updatedProducts[existingProductIndex],
        pro_qty: updatedProducts[existingProductIndex].pro_qty + p_qty,
      };
    } else {
      // If the product doesn't exist, add it to the array
      const newProduct = {
        pro_id: item.id,
        pro_name: item.name,
        pro_image: item.image,
        pro_price: item.price,
        pro_qty: p_qty,
      };
      updatedProducts.push(newProduct);
    }

    // Update the state with the modified 'products' array
    dispatch(setOrderData({ ...orderData, products: updatedProducts }));
  };

  return (
    <Wrapper>
      <div className="product-detail flex flex-col">
        <div className="wrap wrapWidth flex flex-col">
          <div className="pagination flex items-center">
            <Link to="/" className="font-normal underline mr-2">
              Home{" "}
            </Link>
            / {productDetailData?.data?.name}
          </div>
          {!productDetailLoading ? (
            <>
              <div className="page-top flex">
                <div className="pt-left flex flex-col">
                  <div className="page-banner flex items-center w-full my-2">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${productDetailData?.data?.banner}`}
                      className="img"
                    />
                  </div>
                  <div className="product-image flex items-center justify-center">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${productDetailData?.data?.image}`}
                      className="prd-img"
                    />
                  </div>
                </div>
                <div className="pt-right flex flex-col">
                  <div className="prd-info flex flex-col">
                    <h1 className="prd-name">
                      {productDetailData?.data?.name}
                    </h1>
                    <div className="prd-review flex items-center">
                      <div className="rating-bar mr-3">
                        {getStarIcons(productDetailData?.data?.rating)}
                      </div>
                      <h4 className="number-of-rating">
                        {`${productDetailData?.data?.totalreview}  Reviews`}
                      </h4>
                    </div>
                    <h1 className="prd-price">
                      ${productDetailData?.data?.price} / each
                    </h1>
                  </div>
                  <div className="detail-box">
                    {ReactHtmlParser(productDetailData?.data.detail)}
                  </div>
                  <div className="choose-qty-section flex items-center justify-between">
                    <div className="qty-box flex items-center">
                      <button
                        className="btn-desc"
                        onClick={(e) => setQty(qty - 1)}
                        disabled={qty === 1}
                      >
                        <i className="fa-solid fa-minus"></i>
                      </button>
                      <input type="text" value={qty} className="qty btnclear" />
                      <button
                        className="btn-desc"
                        onClick={(e) => setQty(qty + 1)}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </button>
                    </div>
                    <div className="total-price flex items-center">
                      Price: $
                      {(productDetailData?.data?.price * qty)?.toFixed(2)}
                    </div>
                  </div>
                  <div className="add-to-cart-section flex flex-col items-center">
                    <div className="free-shipping-desc">
                      FREE Shipping on orders $75+
                    </div>
                    <div className="age-limitation-desc">
                      Not intended for use by those under the age of 21.
                    </div>
                    <button
                      className="btn-add-cart button"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setOpenSidebar(true));
                        addToCart({
                          item: productDetailData?.data,
                          p_qty: qty,
                        });
                      }}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>

              <div className="product-detail-description-section flex flex-col">
                <div className="selection-tabs flex items-center">
                  {tabsList.map((item, index) => (
                    <div
                      className={`selection-tabs-item flex items-center justify-center ${
                        activeTab === item.lbl ? "active" : ""
                      }`}
                      onClick={(e) => setActiveTab(item.lbl)}
                    >
                      {item.lbl}
                    </div>
                  ))}
                </div>
                <div className="active-tab-detail">
                  {activeTab === "description" ? (
                    <div className="description-detail-tab">
                      {ReactHtmlParser(productDetailData?.data.description)}
                    </div>
                  ) : activeTab === "ingredient" ? (
                    <div className="description-detail-tab">
                      {ReactHtmlParser(productDetailData?.data.ingredients)}
                    </div>
                  ) : activeTab === "faq" ? (
                    <div className="faqs-block flex flex-col">
                      {productDetailData?.productsfaqs?.map((item, index) => (
                        <FaqsItems key={index} item={item} />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="review-question-tabs flex flex-col">
                <div className="review-question-tabs-hdr flex items-center justify-between">
                  <div className="hdr-left-side flex">
                    <div
                      className={`tab-item ${
                        activeActionTab === "reviews" ? "active" : ""
                      }`}
                      onClick={(e) => setActiveActionTab("reviews")}
                    >
                      Reviews
                    </div>
                    <div
                      className={`tab-item ${
                        activeActionTab === "questions" ? "active" : ""
                      }`}
                      onClick={(e) => setActiveActionTab("questions")}
                    >
                      Questions
                    </div>
                  </div>
                  <div className="hdr-right-side flex">
                    <div className="actions flex items-center justify-end">
                      <button
                        className={`btn-create button ${
                          activeActionTab === "writeReview" ? "active" : ""
                        }`}
                        onClick={(e) => setActiveActionTab("writeReview")}
                      >
                        Write a Review
                      </button>
                      <button
                        className={`btn-create button ${
                          activeActionTab === "askQuestion" ? "active" : ""
                        }`}
                        onClick={(e) => setActiveActionTab("askQuestion")}
                      >
                        Ask a Question
                      </button>
                    </div>
                  </div>
                </div>
                {activeActionTab === "reviews" ? (
                  <div className="reviews-section flex flex-col">
                    {productDetailData?.ratings?.length ? (
                      <>
                        {productDetailData?.ratings?.map((item, index) => (
                          <ReviewItem key={index} item={item} />
                        ))}
                      </>
                    ) : (
                      <h1 className="none-message flex items-center justify-center">
                        No Review Yet
                      </h1>
                    )}
                  </div>
                ) : activeActionTab === "questions" ? (
                  <div className="questions-section flex flex-col">
                    {productDetailData?.askquestion?.length ? (
                      <>
                        {productDetailData?.askquestion
                          ?.filter((item) => item?.ans !== null)
                          ?.map((item, index) => (
                            <QuestionItem key={index} item={item} />
                          ))}
                      </>
                    ) : (
                      <h1 className="none-message flex items-center justify-center">
                        No Questions Yet
                      </h1>
                    )}
                  </div>
                ) : activeActionTab === "writeReview" ? (
                  <WriteReview id={productDetailData?.data?.id} />
                ) : activeActionTab === "askQuestion" ? (
                  <AskQuestion id={productDetailData?.data?.id} />
                ) : null}
              </div>
            </>
          ) : (
            <NewLoader />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductDetail;
