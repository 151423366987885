import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/reducers/authReducer";
import { setOpenSidebar } from "../../store/reducers/globalReducer";

import SearchBox from "../SearchBox";
import { useGetCategoryQuery } from "../../store/services/categoryService";

const Header = ({ openNevSidebar, setOpenNevSidebar }) => {
  const {
    isLoading: categoriesLoading,
    isFetching: categoriesFatching,
    data: categoriesList,
  } = useGetCategoryQuery();

  const { orderData } = useSelector((state) => state.globalReducer);
  const totalProQty = orderData?.products.reduce(
    (total, product) => total + product.pro_qty,
    0
  );

  const [showSearch, setShowSearch] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      dispatch(setOpenSidebar(false));
    });
  }, []);

  return (
    <div className="header-camp flex">
      <div className="wrap wrapWidth flex flex-col text-white">
        <div className="header-sec flex items-center">
          <div className="hdr-left flex items-center">
            <div
              className="menu-icon cursor-pointer flex items-center justify-center"
              onClick={(e) => {
                e.stopPropagation();
                setOpenNevSidebar(!openNevSidebar);
              }}
            >
              {openNevSidebar ? (
                <i className="fa-solid fa-xmark text-4xl"></i>
              ) : (
                <i className="fa-solid fa-bars text-3xl"></i>
              )}
            </div>
            <div className="app-logo flex items-center justify-center">
              <Link to="/">
                <img src="/images/logo.png" className="logo" />
              </Link>
            </div>
            <ul className="menu-list flex items-center">
              {categoriesList?.data?.map((item, index) => (
                <li key={index} className="menu-item">
                  <Link to={`/collections/shop/${item.id}`}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="hdr-right flex items-center justify-end">
            <div className="actions flex items-center">
              <i
                className="icon fas fa-search flex items-center justify-center"
                onClick={(e) => setShowSearch(!showSearch)}
              />
              <Link to="/auth/my-account">
                <i className="icon fa-regular fa-user" />
              </Link>
              <i
                className="icon fas fa-regular fa-cart-shopping text-white flex items-center justify-center relative"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setOpenSidebar(true));
                }}
              >
                {orderData?.products?.length ? (
                  <div className="numb absolute">
                    {orderData?.products?.length ? totalProQty : ""}
                  </div>
                ) : null}
              </i>
            </div>
          </div>
        </div>
        {showSearch ? (
          <div className="header-search flex items-center w-full">
            <SearchBox />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
