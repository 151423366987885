import { useState, useEffect } from "react";
import { useUserRegisterMutation } from "../../store/services/authService";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setUserToken } from "../../store/reducers/authReducer";

const Register = ({ setError }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    name: "-",
    email: "",
    phone: "",
    password: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const [register, response] = useUserRegisterMutation();

  const userRegisterFunction = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("email", state.email);
    formData.append("phone", state.phone);
    formData.append("password", state.password);
    register(state);
  };

  useEffect(() => {
    if (response.isSuccess) {
      localStorage.setItem("userToken", response?.data?.data?.token);
      dispatch(setUserToken(response?.data?.data?.token));
      navigate("/");
    }
  }, [response.isSuccess]);

  useEffect(() => {
    if (response.isError) {
      console.log("error message", response);
      setError(response?.error?.data?.message?.email[0]);
    }
  }, [response.isError]);

  return (
    <div className="register-comp flex flex-col w-full">
      <div className="comp-tag">Register</div>
      <form
        onSubmit={userRegisterFunction}
        className="login-form flex flex-col"
      >
        <input
          name="email"
          type="text"
          required
          placeholder="Email address *"
          className="txt-input"
          value={state.email}
          onChange={handleInputs}
        />
        <input
          name="phone"
          type="text"
          required
          placeholder="Enter Phone Number *"
          className="txt-input"
          value={state.phone}
          onChange={handleInputs}
        />
        <input
          name="password"
          type="password"
          required
          placeholder="Password *"
          className="txt-input"
          value={state.password}
          onChange={handleInputs}
        />
        <div className="desc">
          Your personal data will be used to support your experience throughout
          this website, to manage access to your account, and for other purposes
          described in our{" "}
          <Link to="/" className="underline">
            privacy policy.
          </Link>
        </div>
        <div className="action flex items-center">
          {/* <button className="btn-login button">Register</button> */}
          <input
            type="submit"
            className="btn-login button"
            value={response.isLoading ? "Loading..." : "Register"}
          />
        </div>
      </form>
    </div>
  );
};

export default Register;
