import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
  formatDate,
} from "../../utils";

import { useGetOrdersListQuery } from "../../store/services/orderService";

const MyOrdersList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data: orderList,
    isLoading: ordersLoading,
    isSuccess: orderSuccess,
  } = useGetOrdersListQuery();
  //   console.log("what is order list", orderList);

  const orderDetail = (e) => {
    navigate("/order/detail", {
      state: {
        orderDetail: e,
      },
    });
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly23">
          <span className="me-2">Order No.</span>
        </div>
      ),
      selector: (row) => row.id,
      wrap: true,
      width: "160px",
      sortable: true,
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly24">
          <span className="me-2">Order Date</span>
        </div>
      ),
      selector: (row) => formatDate(row.created_at),
      wrap: true,
      width: "300px",
      sortable: true,
    },

    {
      name: "Amount",
      selector: (row) => row.price?.toLocaleString(),
      wrap: true,
      //   width: "170px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "price"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly24">
          <span className="me-2 flex text-center">
            Shipping
            <br /> Price
          </span>
        </div>
      ),
      selector: (row) => row.shippingprice?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "shippingprice"),
    },
    {
      name: "Total Price",
      selector: (row) => row.totalprice?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "totalprice"),
    },
    {
      name: "Total Items",
      selector: (row) => row.products?.length,
      wrap: true,
      //   width: "170px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "products"),
    },
    {
      name: "Status",

      cell: (row) => (
        <div className="flex items-center">
          <div
            className={`order-status  ${
              row.status === "pending" ? "pending" : "approved"
            }`}
          >
            {row.status}
          </div>
        </div>
      ),
      wrap: true,
      //   width: "170px",
    },
    {
      name: "Action",
      width: "100px",
      cell: (row) => (
        <div className="flex items-center">
          <i
            className="fa-regular fa-eye text-lg cursor-pointer hover:text-themeColor"
            onClick={() => {
              orderDetail(row);
            }}
          ></i>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
    table: {
      style: {
        paddingRight: 10,
      },
    },
  };
  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        height: "68px",
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.id % 2 === 0,
      style: {
        height: "68px",
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="my-orders-page flex flex-col w-full">
      <DataTable
        columns={columns}
        customStyles={customStyles}
        data={orderList?.data || []}
        pagination
        conditionalRowStyles={conditionalRowStyles}
      />
      {/* <div className="mt-8 flow-root w-full">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Role
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {orderList.map((item) => (
                  <tr key={item.orderId}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {item.orderDate}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {item.PaymentStatus}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {item.OrderStatus}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {item.orderAmount}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit<span className="sr-only">, {item.orderId}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default MyOrdersList;
