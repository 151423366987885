import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../components/Modal";
import NewLoader from "../../components/NewLoader";
import Wrapper from "../../routes/Wrapper";
import { useGetCategoryQuery } from "../../store/services/categoryService";
import { useGetSliderProductsQuery } from "../../store/services/productService";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
  DayGuarantee,
  USA,
  Verified,
  Farm,
  Munchies,
} from "../../assets/images";

import {
  setOpenSidebar,
  setOrderData,
} from "../../store/reducers/globalReducer";

const Main = () => {
  const {
    isLoading: categoriesLoading,
    isFetching: categoriesFatching,
    data: categoriesList,
  } = useGetCategoryQuery();

  const {
    isLoading: sliderProductsLoading,
    isFetching: sliderProductsFatching,
    data: sliderProductsList,
  } = useGetSliderProductsQuery();

  // console.log("slider Products List...", sliderProductsList);
  const dispatch = useDispatch();
  const { orderData } = useSelector((state) => state.globalReducer);
  const [open, setOpen] = useState(false);

  const baseUrl = process.env.REACT_APP_API_URL;

  const getStarIcons = (rated) => {
    const stars = [];
    const rating = Math.floor(rated); // Get the integer part of the rating
    const isHalfStar = rated % 1 !== 0; // Check if there is a half star

    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        // Render a filled star icon
        stars.push(
          <i key={i} className="fas fa-star text-sm text-yellow-400"></i>
        );
      } else if (isHalfStar && i === rating) {
        // Render a half-filled star icon
        stars.push(
          <i
            key={i}
            className="fas fa-star-half-alt text-sm text-yellow-400"
          ></i>
        );
      } else {
        // Render an empty star icon
        stars.push(<i key={i} className="far fa-star text-sm"></i>);
      }
    }
    return stars;
  };

  const updatedProducts = [...orderData?.products];
  const addToCart = ({ item }) => {
    const existingProductIndex = updatedProducts.findIndex(
      (product) => product.pro_id === item.id
    );

    if (existingProductIndex !== -1) {
      // If the product already exists, increase the quantity
      updatedProducts[existingProductIndex] = {
        ...updatedProducts[existingProductIndex],
        pro_qty: updatedProducts[existingProductIndex].pro_qty + 1,
      };
    } else {
      // If the product doesn't exist, add it to the array
      const newProduct = {
        pro_id: item.id,
        pro_name: item.name,
        pro_image: item.image,
        pro_price: item.price,
        pro_qty: 1,
      };
      updatedProducts.push(newProduct);
    }

    // Update the state with the modified 'products' array
    dispatch(setOrderData({ ...orderData, products: updatedProducts }));
    dispatch(setOpenSidebar(true));
    setTimeout(() => {
      dispatch(setOpenSidebar(true));
    }, 500);
  };

  return (
    <Wrapper>
      <div className="lading-page h-screen flex flex-col">
        <div className="hero-sec flex">
          <div
            className="left-box"
            style={{ backgroundImage: `url("/images/hero1.jpg")` }}
          ></div>
          <div className="right-box flex">
            <div
              className="right-sub-left-box"
              style={{ backgroundImage: `url("/images/hero2.jpg")` }}
            ></div>
            <div className="right-sub-right-box flex flex-col">
              <div
                className="sr-up-box"
                style={{ backgroundImage: `url("/images/hero3.jpg")` }}
              ></div>
              <div
                className="sr-bottom-box"
                style={{ backgroundImage: `url("/images/hero4.jpg")` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="shops-section flex w-full">
          <div className="wrap wrapWidth flex flex-col">
            <div className="shops-grid">
              {categoriesList?.data?.map((item, index) => (
                <Link
                  key={index}
                  to={`/collections/shop/${item.id}`}
                  className="shop-item flex flex-col justify-between"
                >
                  <img src={`${baseUrl}/${item.image}`} className="shop-img" />
                  <div className="shop-meta flex flex-col">
                    <h1 className="shope-name">{item.title}</h1>
                    <p className="shope-desc">{item.content?.slice(0, 230)}</p>
                  </div>
                  <button className="btn-shop button">Shop THC Gummies</button>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="how-baked flex flex-col">
          <div className="wrap wrapWidth flex flex-col">
            <div className="sec-meta flex flex-col justify-center">
              <h1 className="sec-title">How Baked Do You Want to Get?</h1>
              <p className="sec-desc">
                All of our products can get you plenty baked, but how baked you
                get is up to you. Should you take 2 puffs of a vape? Maybe just
                a nibble of a cookie? This cute lil’ guide will help you on your
                quest to greatness!
              </p>
            </div>
            <div className="vapes-sec flex flex-col">
              <h1 className="sec-tag">Vapes</h1>
              <div className="vapes-grid">
                <div className="vapes-item flex flex-col items-center">
                  <img src="/images/eye-1puffs.svg" className="img" />
                  <h1 className="vapes-name">1 PUFF</h1>
                  <p className="vapes-desc">
                    Mildly baked. Like when you put a pizza in and eat it before
                    it’s cooked.
                  </p>
                </div>
                <div className="vapes-item flex flex-col items-center">
                  <img src="/images/eye-1puffs.svg" className="img" />
                  <h1 className="vapes-name">2 PUFF</h1>
                  <p className="vapes-desc">
                    Lifted, but in a mellow way. Still functional, just a little
                    giggly.
                  </p>
                </div>
                <div className="vapes-item flex flex-col items-center">
                  <img src="/images/eye-1puffs.svg" className="img" />
                  <h1 className="vapes-name">3 PUFF</h1>
                  <p className="vapes-desc">
                    Starting to blast off. At this point, you’re plenty baked
                    and feeling great.
                  </p>
                </div>
                <div className="vapes-item flex flex-col items-center">
                  <img src="/images/eye-1puffs.svg" className="img" />
                  <h1 className="vapes-name">4 PUFF</h1>
                  <p className="vapes-desc">
                    Not for those scared of heights. Take 4 puffs and prepare to
                    be on the moon!
                  </p>
                </div>
              </div>
            </div>
            <div className="vapes-sec flex flex-col">
              <h1 className="sec-tag">Edibles</h1>
              <div className="vapes-grid">
                <div className="vapes-item flex flex-col items-center">
                  <img src="/images/icon-cookie-nibble.svg" className="img" />
                  <h1 className="vapes-name">NIBBLE</h1>
                  <p className="vapes-desc">
                    A little nibble will get you going, but you’ll still be
                    functional.
                  </p>
                </div>
                <div className="vapes-item flex flex-col items-center">
                  <img src="/images/icon-cookie-nibble.svg" className="img" />
                  <h1 className="vapes-name">BITE</h1>
                  <p className="vapes-desc">
                    Any more than a nibble and you’re in “bite” territory!
                    Prepare for a good buzz.
                  </p>
                </div>
                <div className="vapes-item flex flex-col items-center">
                  <img src="/images/icon-cookie-nibble.svg" className="img" />
                  <h1 className="vapes-name">SNACK</h1>
                  <p className="vapes-desc">
                    Now you’re getting lifted! You thought you were baked with a
                    bite, but now you’re super blazed!
                  </p>
                </div>
                <div className="vapes-item flex flex-col items-center">
                  <img src="/images/icon-cookie-nibble.svg" className="img" />
                  <h1 className="vapes-name">SCARF</h1>
                  <p className="vapes-desc">
                    This is for certified space cadets only. If you scarf our
                    edibles, you’re going to get baked AF!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="premium-products flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="pbs-header flex items-center justify-center">
              <div className="sec-title">
                Premium HHC, Delta 8, Delta 9, Delta 10, THC-P Products
              </div>
            </div>
            <div className="shops-swiper flex">
              <Swiper
                slidesPerView={4}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  440: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  540: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  820: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  912: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                spaceBetween={20}
                keyboard={{
                  enabled: true,
                }}
                navigation={true}
                modules={[Keyboard, Navigation]}
                className="mySwiper"
              >
                {sliderProductsList?.data?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Link
                      to={`/products/detail/${item.slug}`}
                      className="slider-card flex flex-col justify-between"
                    >
                      <div className="prd-meta flex flex-col">
                        <img
                          src={`${baseUrl}/${item.image}`}
                          alt=""
                          className="prd-img"
                        />
                        <div className="about-product flex flex-col items-center">
                          <div className="prd-review flex items-center">
                            <div className="rating-bar mr-3">
                              {getStarIcons(item?.rating)}
                            </div>
                            <h4 className="number-of-rating">
                              {`(${item?.totalreview}  Reviews)`}
                            </h4>
                          </div>
                          <h1 className="prd-name">{item.name}</h1>
                          <h1 className="prd-price">${item.price}</h1>
                        </div>
                      </div>
                      <div className="action flex items-center w-full">
                        <button
                          className="btn-add-cart button w-full rounded-sm"
                          onClick={(e) => {
                            e.preventDefault();
                            addToCart({ item });
                          }}
                        >
                          Add to Cart
                        </button>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="our-quality-sec flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="quality-meta flex flex-col">
              <div className="sec-tag">OUR COMMITMENT TO QUALITY</div>
              <div className="sec-desc text-center">
                At TRĒ House, we’re on a mission to bring you the best buzz from
                the finest THC products on the planet! We carefully craft each
                of our cannabinoid combinations to get you faded in fun and
                unique ways. We also use the best ingredients, create
                unbelievable flavors, and have all of our products tested at
                ISO-certified third-party labs.
              </div>
            </div>
            <div className="quality-steps-sec flex flex-col">
              <div className="sec-desc text-center">
                If a super potent cannabis plant and a scientist with O.C.D had
                a baby, it would be TRĒ House.
              </div>
              <div className="steps-grid">
                <div className="step-item flex flex-col items-center justify-center">
                  <DayGuarantee className="icon" />
                  <h1 className="step-name">60-Day Guarantee</h1>
                </div>
                <div className="step-item flex flex-col items-center justify-center">
                  <USA className="icon" />
                  <h1 className="step-name">Made in USA</h1>
                </div>
                <div className="step-item flex flex-col items-center justify-center">
                  <Verified className="icon" />
                  <h1 className="step-name">2 Verified Potency</h1>
                </div>
                <div className="step-item flex flex-col items-center justify-center">
                  <Farm className="icon" />
                  <h1 className="step-name">2018 Farm Bill Compliant</h1>
                </div>
                <div className="step-item flex flex-col items-center justify-center">
                  <Munchies className="icon" />
                  <h1 className="step-name">Munchies Guaranteed</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {categoriesLoading ? <NewLoader /> : null}
      </div>
    </Wrapper>
  );
};

export default Main;
