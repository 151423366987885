import React, { useState, useEffect } from "react";

function DateConverter({ originalDate }) {
  //   const originalDate = "2023-06-13T16:04:49.000000Z";
  const [convertedDate, setConvertedDate] = useState("");

  useEffect(() => {
    convertDate();
  }, [originalDate]);

  const convertDate = () => {
    const date = new Date(originalDate);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // Months are 0-based, so add 1
    const day = date.getUTCDate();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    const converted = `${year}-${formattedMonth}-${formattedDay}`;
    setConvertedDate(converted);
  };

  return <div>{convertedDate}</div>;
}

export default DateConverter;
