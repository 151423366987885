import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "../../components/stripe";
import PaymentForm from "../../components/PaymentForm";

const PaymentScreen = () => {
  return (
    <div className="payment-page flex flex-col">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-heard-detail flex items-center">
          <img src="/images/logo.png" className="logo-img" />
        </div>
        <div>
          <Elements stripe={stripePromise}>
            <PaymentForm />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default PaymentScreen;
