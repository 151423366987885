import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import NevSidebar from "../components/NevSidebar";
import AgeVerification from "../components/AgeVerification";
import Modal from "../components/Modal";

const Wrapper = ({ children }) => {
  const { openSidebar, ageVerification } = useSelector(
    (state) => state.globalReducer
  );
  const [open, setOpen] = useState(ageVerification ? false : true);
  const [openNevSidebar, setOpenNevSidebar] = useState(false);

  const handleCloseModal = () => {
    setOpen(ageVerification ? false : true);
    // e.stopPropagation();
    // localStorage.setItem("age", false);
    // dispatch(setAgeVerification(false));
  };
  return (
    <div className={`dashboard-page flex flex-col`}>
      <Sidebar openSidebar={openSidebar} />
      <NevSidebar
        openNevSidebar={openNevSidebar}
        setOpenNevSidebar={setOpenNevSidebar}
      />
      <div className="pages-block flex flex-col relative h-full">
        <Header
          openNevSidebar={openNevSidebar}
          setOpenNevSidebar={setOpenNevSidebar}
        />
        <section>{children}</section>
        <Footer />
      </div>
      <Modal open={open} onClose={handleCloseModal}>
        <AgeVerification />
      </Modal>
    </div>
  );
};
export default Wrapper;
