import React from "react";
import { useDispatch } from "react-redux";
import { setAgeVerification } from "../store/reducers/globalReducer";

const AgeVerification = () => {
  const dispatch = useDispatch();

  return (
    <div className="age-verification-model flex items-center justify-center">
      <div className="meta-block flex flex-col items-center">
        <img src="/images/logo.png" alt="logo" className="logo-image" />
        <h1 className="age-desc">
          Please confirm that you are 21 years of age or older.
        </h1>
        <div className="actions flex items-center justify-center">
          <button
            className="btn_ button"
            onClick={(e) => {
              e.stopPropagation();
              window.location.reload();
              localStorage.setItem("age", true);
              dispatch(setAgeVerification(true));
            }}
          >
            YES
          </button>
          <button
            className="btn_ button"
            onClick={(e) => {
              window.location.reload();
            }}
          >
            NO
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerification;
