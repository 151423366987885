import { useState, useEffect } from "react";
import { useUserLoginMutation } from "../../store/services/authService";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setUserToken } from "../../store/reducers/authReducer";
import { toast } from "react-toastify";

const Login = ({ setError }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const [login, response] = useUserLoginMutation();
  // console.log("login response...", response);

  const userLoginFunction = (e) => {
    e.preventDefault();
    login(state);
  };

  useEffect(() => {
    if (response.isSuccess) {
      localStorage.setItem("userToken", response?.data?.data?.token);
      dispatch(setUserToken(response?.data?.data?.token));
      navigate("/");
      toast.success(response?.data?.message);
    }
  }, [response.isSuccess]);

  useEffect(() => {
    if (response.isError) {
      setError(response?.error?.data?.message);
      toast.error(response?.error?.data?.message);
    }
  }, [response.isError]);

  return (
    <div className="login-comp flex flex-col w-full">
      <div className="comp-tag">Login</div>
      <form onSubmit={userLoginFunction} className="login-form flex flex-col">
        <input
          name="email"
          type="text"
          required
          placeholder="Username or email address *"
          className="txt-input"
          value={state.email}
          onChange={handleInputs}
        />
        <input
          name="password"
          type="password"
          required
          placeholder="Password *"
          className="txt-input"
          value={state.password}
          onChange={handleInputs}
        />
        <div className="action flex items-center">
          {/* <button className="btn-login button">LOG IN</button> */}
          <input
            type="submit"
            className="btn-login button"
            value={response.isLoading ? "Loading..." : "LOG IN"}
          />
          <Link to="/auth/lost-password/" className="lost-password">
            Lost your password?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
