import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const profileService = createApi({
  reducerPath: "userProfile",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.userToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),

  endpoints: (builder) => {
    return {
      updateUserData: builder.mutation({
        query: (userData) => {
          return {
            url: "/api/update-profile",
            method: "POST",
            body: userData,
          };
        },
        invalidatesTags: ["userProfile"],
      }),
      subscribeByEmail: builder.mutation({
        query: (emailData) => {
          return {
            url: "/api/suscribe",
            method: "POST",
            body: emailData,
          };
        },
        invalidatesTags: ["userProfile"],
      }),
      getUserData: builder.query({
        query: () => {
          return {
            url: `/api/profile`,
            method: "GET",
          };
        },
        providesTags: ["userProfile"],
      }),
    };
  },
});
export const {
  useUpdateUserDataMutation,
  useSubscribeByEmailMutation,
  useGetUserDataQuery,
} = profileService;
export default profileService;
