import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setOpenSidebar,
  setOrderData,
} from "../../store/reducers/globalReducer";
import { useGetSettingQuery } from "../../store/services/authService";
import { usePromoCodeMutation } from "../../store/services/orderService";

const Sidebar = () => {
  const dispatch = useDispatch();

  const {
    data: settingData,
    isLoading: settingLoading,
    isSuccess: settingSuccess,
  } = useGetSettingQuery();

  let shippingprice = settingData?.data ? settingData?.data?.shipping : 0;
  const { openSidebar, orderData } = useSelector(
    (state) => state.globalReducer
  );
  // console.log("orderData...", orderData);

  const [state, setState] = useState({
    code: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [showPromoCode, setShowPromoCode] = useState(false);
  const API = process.env.REACT_APP_API_URL;
  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      dispatch(setOpenSidebar(false));
    });
  }, []);

  const ProductCard = ({ item, index }) => {
    const updatedProducts = [...orderData?.products];

    const handleIncrement = ({ index }) => {
      updatedProducts[index] = {
        ...updatedProducts[index],
        pro_qty: updatedProducts[index].pro_qty + 1,
      };
      dispatch(setOrderData({ ...orderData, products: updatedProducts }));
    };
    const handleDecrement = ({ index }) => {
      if (updatedProducts[index].pro_qty > 1) {
        updatedProducts[index] = {
          ...updatedProducts[index],
          pro_qty: updatedProducts[index].pro_qty - 1,
        };
        dispatch(setOrderData({ ...orderData, products: updatedProducts }));
      } else {
        // Remove the product from the array
        updatedProducts.splice(index, 1);
        dispatch(setOrderData({ ...orderData, products: updatedProducts }));
      }
    };
    const handleCancel = (index) => {
      // Remove the specific product object from the products array
      updatedProducts.splice(index, 1);
      dispatch(setOrderData({ ...orderData, products: updatedProducts }));
    };

    return (
      <div key={index} className="item-card flex">
        <div className="img-side flex items-center justify-center">
          <img src={`${API}/${item?.pro_image}`} className="p-img" />
        </div>
        <div className="item-detail flex flex-col w-full">
          <div className="flex items-center justify-between">
            <div className="prd-name">{item.pro_name}</div>
            <button className="btn-cross" onClick={(e) => handleCancel(index)}>
              X
            </button>
          </div>
          <div className="actions flex items-center justify-between">
            <div className="qty-actions flex items-center">
              <button
                className="btn-inc flex items-center justify-center"
                onClick={(e) => handleDecrement({ index })}
              >
                <i className="fa-solid fa-minus"></i>
              </button>
              <div className="bnt-num flex items-center justify-center">
                {item.pro_qty}
              </div>
              <button
                className="btn-inc flex items-center justify-center"
                onClick={(e) => handleIncrement({ index })}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
            <div className="item-price">
              ${(item.pro_price * item.pro_qty)?.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    );
  };
  // Calculate total quantity
  const totalQuantity = orderData?.products?.reduce(
    (total, product) => total + product.pro_qty,
    0
  );

  // Calculate total subamount
  const totalSubAmount = orderData?.products?.reduce(
    (total, product) => total + product.pro_qty * product.pro_price,
    0
  );
  console.log("totalSubAmount...", totalSubAmount);
  console.log("shippingprice...", shippingprice);
  console.log("orderData...", orderData);

  useEffect(() => {
    dispatch(
      setOrderData({
        ...orderData,
        price: totalSubAmount?.toFixed(2),
        shippingprice,
        totalprice: (totalSubAmount + shippingprice).toFixed(2),
        status: "pending",
      })
    );
  }, [totalSubAmount]);

  const [codeData, response] = usePromoCodeMutation();
  // console.log("promo code response...", response);

  const copenCodeFunction = (e) => {
    e.preventDefault();
    codeData(state);
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      if (response?.data?.data.type === "Amount") {
        dispatch(
          setOrderData({
            ...orderData,
            coupon: state.code,
            discount: response?.data?.data.amount,
            totalprice: (totalSubAmount - response?.data?.data.amount)?.toFixed(
              2
            ),
          })
        );
      } else if (response?.data?.data.type === "Percentage") {
        const discountAmount = (
          (orderData?.price * response?.data?.data.amount) /
          100
        ).toFixed(2);

        dispatch(
          setOrderData({
            ...orderData,
            coupon: state.code,
            discount: discountAmount,
            totalprice: (totalSubAmount - discountAmount)?.toFixed(2),
          })
        );
      }
    }
  }, [response.isSuccess]);

  // set null values if user will not selected any single product
  useEffect(() => {
    if (orderData.products.length === 0) {
      dispatch(
        setOrderData({
          ...orderData,
          coupon: "",
          discount: "",
          shippingprice: 0,
          totalprice: 0,
        })
      );
    }
  }, [orderData.products]);

  return (
    <div
      className={`sidebar-s fixed rel anim ${openSidebar ? "show" : "hide"}`}
    >
      <div
        className={`side-block flex col anim ${openSidebar ? "show" : "hide"}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="hdr flex items-center justify-center">
          <div
            className="icon-close flex aic jc"
            onClick={(e) => {
              dispatch(setOpenSidebar(false));
            }}
          >
            <i className="fa-solid fa-arrow-right text-2xl"></i>
          </div>
          <div className="hdr-center flex items-center justify-center flex-1">
            <i className="fa-solid fa-cart-shopping text-[28px]"></i>
            <h3 className="total-items">
              {totalQuantity ? totalQuantity : ""}
            </h3>
          </div>
        </div>
        <div className="items-block flex flex-col">
          {orderData?.products?.length ? (
            <>
              {orderData?.products.map((item, index) => (
                <ProductCard item={item} index={index} />
              ))}
            </>
          ) : (
            <div className="flex items-center justify-center h-full">
              Empty Card
            </div>
          )}
        </div>
        {orderData?.products?.length ? (
          <div className="sidebar-footer flex flex-col">
            {orderData?.discount === "" ? (
              <div className="promo-code flex flex-col">
                <div
                  className={`pc-lbl underline ${showPromoCode ? "hide" : ""}`}
                  onClick={(e) => setShowPromoCode(true)}
                >
                  Have a promo code? Click here.
                </div>
                {response?.isSuccess ? (
                  <>
                    <div className="alert-success my-5">
                      {response?.data?.message
                        ? response?.data?.message
                        : "Congratulation!"}
                    </div>
                  </>
                ) : (
                  <>
                    <form
                      onSubmit={copenCodeFunction}
                      className={`code-from flex items-center ${
                        showPromoCode ? "show" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="code"
                        placeholder="Enter Promo Code"
                        className="txt cleanbtn"
                        value={state.code}
                        onChange={handleInputs}
                      />

                      <input
                        type="submit"
                        className="btn-apply button"
                        value={response.isLoading ? "Loading..." : "Apply"}
                      />
                    </form>
                    {response?.isError && (
                      <div className="alert-danger my-5">
                        {response?.data?.message
                          ? response?.data?.message
                          : "Promo code not valid!"}
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : null}
            <div className="action flex flex-col">
              <div className="sub-total-sec flex items-center justify-between">
                <h1 className="lbl">Subtotal</h1>
                <h1 className="amount">
                  $ {totalSubAmount ? totalSubAmount?.toFixed(2) : ""}
                </h1>
              </div>
              {orderData?.discount !== "" ? (
                <div className="sub-total-sec flex items-center justify-between">
                  <h1 className="lbl">Discount</h1>
                  <h1 className="amount">
                    $ {orderData?.discount ? orderData?.discount : ""}
                  </h1>
                </div>
              ) : null}
              <Link to="/dashboard/checkout">
                <button
                  className="btn-checkout button w-full"
                  onClick={(e) => dispatch(setOpenSidebar(false))}
                >
                  Process To Checkout
                </button>
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Sidebar;
