import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const categoryService = createApi({
  reducerPath: "categories",
  tagTypes: "category",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => {
    return {
      getCategory: builder.query({
        query: () => {
          return {
            url: `/api/categories`,
            method: "GET",
          };
        },
        providesTags: ["category"],
      }),
      getCategoryDetail: builder.query({
        query: (id) => {
          return {
            url: `/api/category/${id}`,
            method: "GET",
          };
        },
        providesTags: ["category"],
      }),
    };
  },
});
export const { useGetCategoryQuery, useGetCategoryDetailQuery } =
  categoryService;
export default categoryService;
