import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authService = createApi({
  reducerPath: "auth",
  tagTypes: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),

  endpoints: (builder) => {
    return {
      getSetting: builder.query({
        query: () => {
          return {
            url: `/api/setting`,
            method: "GET",
          };
        },
        providesTags: ["auth"],
      }),
      userRegister: builder.mutation({
        query: (data) => {
          return {
            url: "/api/register",
            method: "POST",
            body: data,
          };
        },
      }),
      userLogin: builder.mutation({
        query: (loginData) => {
          return {
            url: "/api/login",
            method: "POST",
            body: loginData,
          };
        },
      }),
      userPasswordReset: builder.mutation({
        query: (resetData) => {
          return {
            url: "/api/forgot",
            method: "POST",
            body: resetData,
          };
        },
      }),
      passwordResetPin: builder.mutation({
        query: (pinData) => {
          return {
            url: "/api/verification-pin",
            method: "POST",
            body: pinData,
          };
        },
      }),
      passwordUpdate: builder.mutation({
        query: (pinData) => {
          return {
            url: "/api/changepassword",
            method: "POST",
            body: pinData,
          };
        },
      }),
    };
  },
});
export const {
  useGetSettingQuery,
  useUserRegisterMutation,
  useUserLoginMutation,
  useUserPasswordResetMutation,
  usePasswordResetPinMutation,
  usePasswordUpdateMutation,
} = authService;
export default authService;
