import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useProductQuestionMutation } from "../../store/services/productService";

const AskQuestion = ({ id }) => {
  const [state, setState] = useState({
    title: "",
    name: "",
    email: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  console.log("question data", state);

  const [productQuestionData, response] = useProductQuestionMutation();
  console.log("product question response...", response);

  const productQuestionFunction = (e) => {
    e.preventDefault();
    productQuestionData({ state, id });
  };

  useEffect(() => {
    if (response.isSuccess) {
      window?.location?.reload();
      toast.success(response?.data?.message);
    }
  }, [response.isSuccess]);

  return (
    <div className="flex flex-col w-full">
      <form onSubmit={productQuestionFunction} className="flex flex-col w-full">
        <h1 className="text-2xl font-semibold text-black my-5">
          Submit a Question
        </h1>

        <div className="input-field flex flex-col w-full mb-5">
          <label
            htmlFor="title"
            className="block text-lg font-medium leading-6 text-gray-900"
          >
            Question
            <span className="text-base text-red-800 font-normal">
              (required)
            </span>
          </label>
          <div className="mt-2">
            <textarea
              type="text"
              name="title"
              id="title"
              required
              className="block w-full min-h-[180px] rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
              placeholder="Question..."
              aria-describedby="title-description"
              value={state?.title}
              onChange={handleInputs}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description"></p>
        </div>

        <div className="row2">
          <div className="input-field flex flex-col w-full mb-5">
            <label
              htmlFor="name"
              className="block text-lg font-medium leading-6 text-gray-900"
            >
              Your Name{" "}
              <span className="text-base text-red-800 font-normal">
                (required)
              </span>
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="name"
                id="name"
                required
                className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
                placeholder="Your Name..."
                aria-describedby="name-description"
                value={state?.name}
                onChange={handleInputs}
              />
            </div>
            <p
              className="mt-2 text-sm text-gray-500"
              id="email-description"
            ></p>
          </div>
          <div className="input-field flex flex-col w-full mb-5">
            <label
              htmlFor="email"
              className="block text-lg font-medium leading-6 text-gray-900"
            >
              Email{" "}
              <span className="text-base text-red-800 font-normal">
                (required)
              </span>
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="email"
                id="email"
                required
                className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeColor sm:text-sm sm:leading-6"
                placeholder="Email"
                aria-describedby="email-description"
                value={state?.email}
                onChange={handleInputs}
              />
            </div>
            <p
              className="mt-2 text-sm text-gray-500"
              id="email-description"
            ></p>
          </div>
        </div>

        <input
          type="submit"
          className="btn-save button w-max mb-10 rounded-md"
          value={response.isLoading ? "Loading..." : "SUBMIT QUESTION"}
        />
      </form>
    </div>
  );
};

export default AskQuestion;
