import { configureStore } from "@reduxjs/toolkit";

import authService from "./services/authService";
import categoryService from "./services/categoryService";
import productService from "./services/productService";
import orderService from "./services/orderService";
import profileService from "./services/profileService";

import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";

const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [categoryService.reducerPath]: categoryService.reducer,
    [productService.reducerPath]: productService.reducer,
    [orderService.reducerPath]: orderService.reducer,
    [profileService.reducerPath]: profileService.reducer,

    authReducer: authReducer,
    globalReducer: globalReducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authService.middleware,
      categoryService.middleware,
      productService.middleware,
      orderService.middleware,
      profileService.middleware,
    ]),
});

export default Store;
