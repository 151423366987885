import React, { useState } from "react";
import MyOrdersList from "./MyOrdersList";
import ProfileUpdate from "./ProfileUpdate";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, setActiveTab } from "../../store/reducers/authReducer";

const AccountDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeTab } = useSelector((state) => state.authReducer);

  const tabList = [
    { label: "Orders", id: "orders" },
    { label: "Account Detail", id: "accountDetail" },
  ];

  const userlogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="account-detail-page flex flex-col w-full">
      <div className="page-tabs flex items-center my-4 rounded-sm border-2 border-black  border-solid">
        {tabList.map((item, index) => (
          <div
            key={index}
            className={`tab-item flex h-full items-center justify-center p-2 cursor-pointer flex-1 text-lg ${
              activeTab === item.id ? "bg-black text-white" : ""
            }`}
            onClick={(e) => {
              dispatch(setActiveTab(item.id));
              localStorage.setItem("activeTab", item.id);
            }}
          >
            {item.label}
          </div>
        ))}
        <div
          className={`tab-item flex h-full items-center justify-center p-2 cursor-pointer flex-1 text-lg }`}
          onClick={userlogout}
        >
          Logout
        </div>
      </div>

      <div className="selected-tab flex w-full">
        {activeTab === "orders" ? (
          <MyOrdersList />
        ) : activeTab === "accountDetail" ? (
          <ProfileUpdate />
        ) : null}
      </div>
    </div>
  );
};

export default AccountDetail;
