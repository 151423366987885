import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useGetCategoryQuery } from "../../store/services/categoryService";

const NevSidebar = ({ openNevSidebar, setOpenNevSidebar }) => {
  const API = process.env.REACT_APP_API_URL;

  const {
    isLoading: categoriesLoading,
    isFetching: categoriesFatching,
    data: categoriesList,
  } = useGetCategoryQuery();

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      setOpenNevSidebar(false);
    });
  }, []);

  return (
    <div
      className={`nev-sidebar-s fixed rel anim ${
        openNevSidebar ? "show" : "hide"
      }`}
    >
      <div
        className={`nev-side-block flex col anim ${
          openNevSidebar ? "show" : "hide"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="items-block flex flex-col">
          <h1 className="sec-title">SHOPS</h1>
          <div className="list flex flex-col">
            {categoriesList?.data?.map((item, index) => (
              <Link
                key={index}
                to={`/collections/shop/${item.id}`}
                className="list-itm"
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NevSidebar;
