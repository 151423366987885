function timeToSeconds(time) {
  // Parse the time string into hours, minutes, and AM/PM components
  var match = time.match(/(\d+):(\d+)\s*(AM|PM)/);
  var hours = parseInt(match[1]);
  var minutes = parseInt(match[2]);
  var isPM = match[3] === "PM";

  // Adjust the hours component if the time is in the PM
  if (isPM && hours !== 12) {
    hours += 12;
  }

  if (!isPM && hours == 12) {
    hours = 0;
  }
  // Convert the time to seconds
  var totalSeconds = hours * 3600 + minutes * 60;

  return totalSeconds;
}

export const customDateSort = (rowA, rowB, title, isFormatted) => {
  let a = rowA[title];
  let b = rowB[title];
  if (isFormatted) {
    a = new Date(rowA[title]).getTime();
    b = new Date(rowB[title]).getTime();
  } else {
    let splitA = a.split("/");
    a = new Date(splitA[2], splitA[1], splitA[0]).getTime();
    let splitB = b.split("/");
    b = new Date(splitB[2], splitB[1], splitB[0]).getTime();
  }

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const customStringSort = (str1, str2, title, isNested) => {
  let s1;
  let s2;

  if (isNested) {
    let value = title.split(".");
    s1 = str1;
    s2 = str2;
    for (let val of value) {
      s1 = s1[val];
      s2 = s2[val];
    }
  } else {
    s1 = str1[title];
    s2 = str2[title];
  }

  if (!s1) return -1;
  if (!s2) return 1;

  console.log(s1);
  const result = s1.localeCompare(s2);

  if (result < 0) {
    return -1;
  } else if (result > 0) {
    return 1;
  } else {
    return 0;
  }
};

export const customTimeSort = (rowA, rowB, title) => {
  const a = timeToSeconds(rowA[title]);
  const b = timeToSeconds(rowB[title]);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const customNumberSort = (rowA, rowB, title) => {
  const a = parseInt(rowA[title]);
  const b = parseInt(rowB[title]);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const customArrayDateSort = (array) => {
  const sortedArray = array.sort((a, b) => {
    return new Date(b) - new Date(a);
  });

  return sortedArray;
};

export const formatDate = (dateTimeString) => {
  // Create a Date object from the provided string
  const date = new Date(dateTimeString);

  // Define options for formatting
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    // timeZoneName: "short",
  };

  // Convert the Date object to a human-readable string
  return date.toLocaleString(undefined, options);
};
