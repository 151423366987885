import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const orderService = createApi({
  reducerPath: "order",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.userToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),

  endpoints: (builder) => {
    return {
      userOrder: builder.mutation({
        query: (orderData) => {
          return {
            url: "/api/createorder",
            method: "POST",
            body: orderData,
          };
        },
        invalidatesTags: ["order"],
      }),
      promoCode: builder.mutation({
        query: (code) => {
          return {
            url: "/api/coupon",
            method: "POST",
            body: code,
          };
        },
        invalidatesTags: ["order"],
      }),
      getOrdersList: builder.query({
        query: () => {
          return {
            url: `/api/orders`,
            method: "GET",
          };
        },
        providesTags: ["order"],
      }),
    };
  },
});
export const {
  useUserOrderMutation,
  usePromoCodeMutation,
  useGetOrdersListQuery,
} = orderService;
export default orderService;
