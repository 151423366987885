import React from "react";

const RatingInput = ({ rating, setRating }) => {
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  return (
    <div className="rating-input flex items-center">
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          className={`star ${star <= rating ? "active" : ""}`}
          onClick={() => handleRatingChange(star)}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};

export default RatingInput;
