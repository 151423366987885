import React from "react";
import Wrapper from "../../routes/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import Login from "./Login";
import Register from "./Register";
import AccountDetail from "./AccountDetail";
import { useState } from "react";

const Account = () => {
  const { userToken } = useSelector((state) => state.authReducer);
  // console.log("adminToken", userToken);
  const [error, setError] = useState(null);

  return (
    <Wrapper>
      <div className="my-account-page flex flex-col">
        <div className="page-hdr flex items-center justify-center">
          <h1 className="hdr-tag">My account</h1>
        </div>
        <div className="wrap wrapWidth flex flex-col">
          {error ? <div className="alert-danger my-5">{error}</div> : null}
          {userToken ? (
            <AccountDetail />
          ) : (
            <div className="auth-div flex">
              <div className="left-side flex">
                <Login setError={setError} />
              </div>
              <div className="right-side flex">
                <Register setError={setError} />
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Account;
