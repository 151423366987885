import React, { useEffect } from "react";

import { Link, useParams, useLocation } from "react-router-dom";
import Wrapper from "../../routes/Wrapper";
import NewLoader from "../../components/NewLoader";
import { useDispatch } from "react-redux";
import { useGetSearchedProductsQuery } from "../../store/services/productService";
import ProductCard from "../../components/ProductCard";

import { setOpenSidebar } from "../../store/reducers/globalReducer";

const SearchedProducts = () => {
  const { slug } = useParams();

  const baseUrl = process.env.REACT_APP_API_URL;
  const {
    isLoading: productsLoading,
    isFetching: productsFetching,
    data: productsData,
  } = useGetSearchedProductsQuery(slug);

  console.log("searched products data...", productsData);

  const dispatch = useDispatch();
  const location = useLocation();
  console.log("what is url location...", location);
  console.log("what is url slug...", slug);

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      dispatch(setOpenSidebar(false));
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // window.location.reload();
  }, [location]);

  return (
    <Wrapper>
      <div className="collections-page flex flex-col">
        <div className="wrap wrapWidth flex flex-col">
          <div className="pagination flex items-center">
            <Link to="/" className="font-normal underline mr-2">
              Home{" "}
            </Link>{" "}
            / You searched for {slug}
          </div>

          {/* <div className="page-banner flex items-center w-full my-12">
            <img
              src={`${baseUrl}/${categoryDetailData?.data.banner}`}
              className="h-28 w-full rounded-sm"
            />
          </div> */}
          <div className="products-block flex flex-col my-12">
            <div className="block-hdr flex items-center">
              <h1 className="total-products">
                Showing all {productsData?.data?.length} results
              </h1>
            </div>
            <>
              {productsData?.data?.length ? (
                <div className="products-grid">
                  {productsData?.data?.map((item, index) => (
                    <ProductCard item={item} index={index} />
                  ))}
                </div>
              ) : (
                <div className="w-full h-screen flex items-center justify-center">
                  <h1 className=" text-3xl font-medium text-[#212529]">
                    Product not found
                  </h1>
                </div>
              )}
            </>
          </div>
        </div>
        {productsLoading | productsFetching ? <NewLoader /> : null}
      </div>
    </Wrapper>
  );
};

export default SearchedProducts;
