import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link, useParams, useLocation } from "react-router-dom";
import Wrapper from "../../routes/Wrapper";
import NewLoader from "../../components/NewLoader";
import { useDispatch } from "react-redux";
import { useGetCategoryDetailQuery } from "../../store/services/categoryService";
import ProductCard from "../../components/ProductCard";
import {
  DayGuarantee,
  USA,
  Verified,
  Farm,
  Munchies,
} from "../../assets/images";

import { MinusSmallIcon, PlusSmallIcon } from "../../assets/icons";
import { setOpenSidebar } from "../../store/reducers/globalReducer";

const Collections = () => {
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_API_URL;
  const {
    isLoading: categoryDetailLoading,
    isFetching: categoryDetailFetching,
    data: categoryDetailData,
  } = useGetCategoryDetailQuery(id);

  const dispatch = useDispatch();
  const location = useLocation();

  const faqs = [
    {
      question: "What's the difference between CBD and THC?",
      answer:
        "CBD and THC are both immensely popular compounds that are produced by cannabis. THC is a psychotropic cannabinoid that is typically used for recreational purposes. CBD is a non-psychotropic cannabinoid that is primarily used for wellness purposes. Simply put: THC gets you baked and CBD does not.",
    },
    {
      question: "How long do THC gummies last in your system?",
      answer:
        "Users will typically feel the effects of THC gummies for anywhere from 4 to 8 hours. For regular users, THC will still be detectable in the body for anywhere from 1 week to 3 months. If you’re concerned about taking a drug test, you should consult your primary physician before consuming THC.",
    },
    {
      question:
        "How many milligrams of THC does it take to get high?/How much THC is needed to get a starting high?",
      answer:
        "Everybody metabolizes THC differently. Some people get ripped off just a little THC, and some people require much higher doses to feel the effects. Generally speaking, a small serving of THC is 2-5mg, a medium serving is 10-20mg, and anything above is definitely considered highly potent. Being that our gummies range from 20-35mg, these babies are super potent! If you prefer something less potent, just take a nibble and enjoy the savings.",
    },
    {
      question: "What are the most powerful THC gummies?",
      answer:
        "TRĒ House gummies are the most powerful THC gummies, duh! These babies are packed with 20-35mg of premium THC! We also utilize unique blends of cannabinoids that offer a buzz unlike any other. Each compound was carefully selected for its effects and these impressive medleys of cannabinoids are sure to blow your mind!",
    },
    {
      question: "Are THC edibles potentially dangerous?",
      answer:
        "THC is not particularly dangerous, but doing stupid stuff when you’re inebriated is. THC can get you pretty ripped… DO NOT operate heavy machinery, drive, use power tools, or do any other dumb things when using THC. Love you… Just be safe <3",
    },
  ];

  const FaqsItems = ({ key, item }) => {
    const [expend, setExpend] = useState(false);
    return (
      <div key={key} className="faq-item flex flex-col">
        <div
          className="item-hdr flex items-center justify-between cursor-pointer"
          onClick={(e) => setExpend(!expend)}
        >
          <h1 className="qustion">{item.question}</h1>
          <button
            className="icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setExpend(!expend)}
          >
            {expend ? <MinusSmallIcon /> : <PlusSmallIcon />}
          </button>
        </div>
        <p className={`answer ${expend ? "show" : ""}`}>{item.answer}</p>
      </div>
    );
  };

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      dispatch(setOpenSidebar(false));
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Wrapper>
      <div className="collections-page flex flex-col">
        <div className="wrap wrapWidth flex flex-col">
          <div className="pagination flex items-center">
            <Link to="/" className="font-normal underline mr-2">
              Home{" "}
            </Link>{" "}
            / {categoryDetailData?.data.slug}
          </div>
          <div className="page-name flex items-center bg-black p-6">
            <h1 className="text-white font-semibold text-4xl">
              {categoryDetailData?.data.title}
            </h1>
          </div>
          <div className="page-banner flex items-center w-full my-12">
            <img
              src={`${baseUrl}/${categoryDetailData?.data.banner}`}
              className="h-28 w-full rounded-sm"
            />
          </div>
          <div className="products-block flex flex-col">
            <div className="block-hdr flex items-center">
              <h1 className="total-products">
                Showing all {categoryDetailData?.products.length} results
              </h1>
            </div>
            <div className="products-grid">
              {categoryDetailData?.products.map((item, index) => (
                <ProductCard item={item} index={index} />
              ))}
            </div>
          </div>
          <div className="steps-grid">
            <div className="step-item flex flex-col items-center justify-center">
              <DayGuarantee className="icon" />
              <h1 className="step-name">60-Day Guarantee</h1>
            </div>
            <div className="step-item flex flex-col items-center justify-center">
              <USA className="icon" />
              <h1 className="step-name">Made in USA</h1>
            </div>
            <div className="step-item flex flex-col items-center justify-center">
              <Verified className="icon" />
              <h1 className="step-name">2 Verified Potency</h1>
            </div>
            <div className="step-item flex flex-col items-center justify-center">
              <Farm className="icon" />
              <h1 className="step-name">2018 Farm Bill Compliant</h1>
            </div>
            <div className="step-item flex flex-col items-center justify-center">
              <Munchies className="icon" />
              <h1 className="step-name">Munchies Guaranteed</h1>
            </div>
          </div>
          <div className="detail-box">
            {ReactHtmlParser(categoryDetailData?.data.detail)}
          </div>

          <div className="faqs-section flex flex-col">
            <h1 className="faqs-heading">FREQUENTLY ASKED QUESTIONS</h1>
            <div className="faqs-block flex flex-col">
              {faqs.map((item, index) => (
                <FaqsItems key={index} item={item} />
              ))}
            </div>
          </div>
        </div>
        {categoryDetailLoading | categoryDetailFetching ? <NewLoader /> : null}
      </div>
    </Wrapper>
  );
};

export default Collections;
