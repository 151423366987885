import { createSlice } from "@reduxjs/toolkit";
const customerToken = localStorage.getItem("userToken");
const activeTab = localStorage.getItem("activeTab");
const resetPassword = JSON.parse(localStorage.getItem("resetPassword"));

const authReducer = createSlice({
  name: "authReducer",
  initialState: {
    userToken: customerToken ? customerToken : "",
    activeTab: activeTab ? activeTab : "orders",
    resetPassword: resetPassword
      ? resetPassword
      : {
          email: "",
          pin: "",
          password: "",
        },
  },
  reducers: {
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setResetPassword: (state, action) => {
      state.resetPassword = action.payload;
    },
    logout: (state, { payload }) => {
      localStorage.removeItem("userToken");
      localStorage.removeItem("activeTab");
      localStorage.removeItem("orderData");
      localStorage.removeItem(payload);
      state.userToken = null;
    },
  },
});
export const { setUserToken, setActiveTab, logout, setResetPassword } =
  authReducer.actions;
export default authReducer.reducer;
