import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Wrapper from "../../routes/Wrapper";

const OrderDetail = () => {
  const api = process.env.REACT_APP_API_URL;
  const { state } = useLocation();
  const [products, setProducts] = useState([]);
  // console.log("what is state", state?.orderDetail);

  useEffect(() => {
    setProducts(state?.orderDetail);
    window.scrollTo(0, 0);
  }, [state]);

  return (
    <Wrapper>
      <main className="bg-white px-4 pb-24 pt-16 sm:px-6 sm:pt-24 lg:px-8 lg:py-32">
        <div className="mx-auto max-w-3xl">
          <div className="max-w-xl">
            <h1 className="text-base font-medium text-indigo-600">
              Thank you!
            </h1>
            <p className="mt-2 text-4xl font-bold tracking-tight">
              Order Detail
            </p>
            <p className="my-2 text-base text-gray-500">
              Your order #{state?.orderDetail?.id} has shipped and will be with
              you soon.
            </p>
            <div
              className={`order-status flex items-center justify-center ${
                state?.orderDetail.status === "pending" ? "pending" : "approved"
              }`}
            >
              {state?.orderDetail.status}
            </div>
            {/* <dl className="mt-12 text-sm font-medium">
            <dt className="text-gray-900">Tracking number</dt>
            <dd className="mt-2 text-indigo-600">51547878755545848512</dd>
          </dl> */}
          </div>

          <section
            aria-labelledby="order-heading"
            className="mt-10 border-t border-gray-200"
          >
            <h2 id="order-heading" className="sr-only">
              Your order
            </h2>

            <h3 className="sr-only">Items</h3>
            {Array.isArray(state?.orderDetail?.products) &&
            state?.orderDetail.products.length > 0 ? (
              <>
                {state?.orderDetail?.products.map((item, index) => (
                  <div
                    key={index}
                    className="flex space-x-6 border-b border-gray-200 py-10"
                  >
                    <img
                      // src={"./images/p1.webp"}
                      src={`${api}/${item.pro_image}`}
                      alt={item.pro_image}
                      className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
                    />
                    <div className="flex flex-auto flex-col">
                      <div>
                        <h4 className="font-medium text-gray-900">
                          <a href={item.pro_name}>{item.pro_name}</a>
                        </h4>
                        <p className="mt-2 text-sm text-gray-600">
                          {/* {item.description} */}
                        </p>
                      </div>
                      <div className="mt-6 flex flex-1 items-end">
                        <div className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                          <div className="flex">
                            <dt className="font-medium text-gray-900">
                              Quantity
                            </dt>
                            <dd className="ml-2 text-gray-700">
                              {item.pro_qty}
                            </dd>
                          </div>
                          <div className="flex pl-4 sm:pl-6">
                            <div className="font-medium text-gray-900">
                              Price
                            </div>
                            <div className="ml-2 text-gray-700">
                              {item.pro_price}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
            <div className="sm:ml-40 sm:pl-6">
              <h3 className="sr-only">Your information</h3>

              <h4 className="sr-only">Addresses</h4>
              <dl className="grid grid-cols-2 gap-x-6 py-10 text-sm">
                <div>
                  <dt className="font-medium text-gray-900">
                    Shipping address
                  </dt>
                  <dd className="mt-2 text-gray-700">
                    <address className="not-italic">
                      <span className="block">
                        {state?.orderDetail?.country}
                      </span>
                      <span className="block">
                        {state?.orderDetail?.city}, {state?.orderDetail?.state}{" "}
                      </span>
                      <span className="block">
                        {state?.orderDetail?.address}
                      </span>
                    </address>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">Billing address</dt>
                  <dd className="mt-2 text-gray-700">
                    <address className="not-italic">
                      <span className="block">
                        {state?.orderDetail?.country}
                      </span>
                      <span className="block">
                        {state?.orderDetail?.city}, {state?.orderDetail?.state}{" "}
                      </span>
                      <span className="block">
                        {state?.orderDetail?.address}
                      </span>
                    </address>
                  </dd>
                </div>
              </dl>

              {/* <h4 className="sr-only">Payment</h4>
            <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
              <div>
                <dt className="font-medium text-gray-900">Payment method</dt>
                <dd className="mt-2 text-gray-700">
                  <p>Apple Pay</p>
                  <p>Mastercard</p>
                  <p>
                    <span aria-hidden="true">••••</span>
                    <span className="sr-only">Ending in </span>1545
                  </p>
                </dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">Shipping method</dt>
                <dd className="mt-2 text-gray-700">
                  <p>DHL</p>
                  <p>Takes up to 3 working days</p>
                </dd>
              </div>
            </dl> */}

              <h3 className="sr-only">Summary</h3>

              <dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
                <div className="flex justify-between">
                  <dt className="font-medium text-gray-900">Subtotal</dt>
                  <dd className="text-gray-700">
                    ${state?.orderDetail?.price}
                  </dd>
                </div>
                <div className="flex justify-between">
                  <dt className="flex font-medium text-gray-900">
                    Discount
                    {/* <span className="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-600">
                    STUDENT50
                  </span> */}
                  </dt>
                  <dd className="text-gray-700">
                    -$
                    {state?.orderDetail?.discount
                      ? state?.orderDetail?.discount
                      : "0.0"}
                  </dd>
                </div>
                <div className="flex justify-between">
                  <dt className="font-medium text-gray-900">Shipping</dt>
                  <dd className="text-gray-700">
                    ${state?.orderDetail?.shippingprice}
                  </dd>
                </div>
                <div className="flex justify-between">
                  <dt className="font-medium text-gray-900">Total</dt>
                  <dd className="text-gray-900">
                    ${state?.orderDetail?.totalprice}
                  </dd>
                </div>
              </dl>
            </div>
          </section>
        </div>
      </main>
    </Wrapper>
  );
};

export default OrderDetail;
