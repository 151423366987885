import { createSlice } from "@reduxjs/toolkit";

const ageVerification = localStorage.getItem("age");
const orderData = JSON.parse(localStorage.getItem("orderData"));

const globalReducer = createSlice({
  name: "global",
  initialState: {
    success: "",
    searchBar: false,
    openSidebar: false,
    ageVerification: ageVerification ? ageVerification : false,
    orderData: orderData
      ? orderData
      : {
          user_email: "",
          user_first_name: "",
          user_last_name: "",
          country: "",
          zipcode: "",
          state: "",
          address: "",
          apartment: "",
          city: "",
          user_phone: "",
          price: "",
          shippingprice: "",
          coupon: "",
          discount: "",
          totalprice: 0,
          status: "pending",
          stripeToken: "",
          products: [],
        },
  },
  reducers: {
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setAgeVerification: (state, action) => {
      state.ageVerification = action.payload;
    },
    setOpenSidebar: (state, action) => {
      state.openSidebar = action.payload;
    },
    clearMessage: (state) => {
      state.success = "";
    },
    toggleSearchBar: (state) => {
      state.searchBar = !state.searchBar;
    },
    setOrderData: (state, action) => {
      localStorage.setItem("orderData", JSON.stringify(action.payload));
      state.orderData = action.payload;
    },
    clearCart: (state, { payload }) => {
      localStorage.removeItem("orderData");
      state.orderData = {
        user_email: "",
        user_first_name: "",
        user_last_name: "",
        country: "",
        zipcode: "",
        state: "",
        address: "",
        apartment: "",
        city: "",
        user_phone: "",
        price: "",
        shippingprice: "",
        totalprice: 0,
        status: "pending",
        stripeToken: "",
        products: [],
      };
    },
  },
});
export const {
  setSuccess,
  clearMessage,
  toggleSearchBar,
  setOpenSidebar,
  setOrderData,
  clearCart,
  setAgeVerification,
} = globalReducer.actions;
export default globalReducer.reducer;
