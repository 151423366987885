/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.css";

import "./css/App.scss";
import "./index.css";

import Routing from "./routes/Routing";

function App() {
  return <Routing />;
}

export default App;
