import React, { useState, useEffect } from "react";
import Wrapper from "../../routes/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setResetPassword, logout } from "../../store/reducers/authReducer";
import {
  useUserPasswordResetMutation,
  usePasswordResetPinMutation,
  usePasswordUpdateMutation,
} from "../../store/services/authService";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { resetPassword } = useSelector((state) => state.authReducer);
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleInputs = (e) => {
    dispatch(
      setResetPassword({ ...resetPassword, [e.target.name]: e.target.value })
    );
  };

  const [resetData, response] = useUserPasswordResetMutation();
  const userResetFunction = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", resetPassword?.email);
    resetData(formData);
  };
  useEffect(() => {
    if (response.isSuccess) {
      if (response?.data?.success) {
        setStep(2);
        setSuccessMessage(response?.data?.message);
      } else {
        setError(response?.data?.message);
      }
      //   dispatch(setUserToken(response?.data?.data?.token));
      //   navigate("/");
    }
  }, [response.isSuccess]);

  useEffect(() => {
    if (response.isError) {
      setError("Something was wrong! Try again later.");
    }
  }, [response.isError]);

  const [pinData, pinResponse] = usePasswordResetPinMutation();
  const pinEnterFunction = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", resetPassword?.email);
    formData.append("pin", resetPassword?.pin);
    pinData(formData);
  };
  useEffect(() => {
    if (pinResponse.isSuccess) {
      if (pinResponse?.data?.success) {
        setStep(3);
        setSuccessMessage(pinResponse?.data?.message);
      } else {
        setError(pinResponse?.data?.message);
      }
    }
  }, [pinResponse.isSuccess]);

  useEffect(() => {
    if (pinResponse.isError) {
      setError("Something was wrong! Try again later.");
    }
  }, [pinResponse.isError]);

  const [newPassData, passwordUpdateResponse] = usePasswordUpdateMutation();
  const newPasswordFunction = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", resetPassword?.email);
    formData.append("password", resetPassword?.password);
    newPassData(formData);
  };
  useEffect(() => {
    if (passwordUpdateResponse.isSuccess) {
      if (passwordUpdateResponse?.data?.success) {
        navigate("/auth/my-account");
        dispatch(logout());
        localStorage.removeItem("resetPassword");
        setSuccessMessage(passwordUpdateResponse?.data?.message);
      } else {
        setError(passwordUpdateResponse?.data?.message);
      }
    }
  }, [passwordUpdateResponse.isSuccess]);

  useEffect(() => {
    if (passwordUpdateResponse.isError) {
      setError("Something was wrong! Try again later.");
    }
  }, [passwordUpdateResponse.isError]);

  return (
    <Wrapper>
      <div className="forget-password flex flex-col">
        <div className="page-hdr flex items-center justify-center">
          <h1 className="hdr-tag">Lost password</h1>
        </div>
        <div className="wrap wrapWidth flex flex-col">
          {step === 1 ? (
            <form
              onSubmit={userResetFunction}
              className="login-form flex flex-col"
            >
              {error ? <div className="alert-danger my-5">{error}</div> : null}
              {successMessage ? (
                <div className="alert-success my-5">{successMessage}</div>
              ) : null}
              <div className="desc">
                Lost your password? Please enter your username or email address.
                You will receive a link to create a new password via email.
              </div>

              <div className="input-field flex flex-col">
                <label className="field-lbl mb-2">Email</label>
                <input
                  name="email"
                  type="text"
                  required
                  placeholder="Email address *"
                  className="txt-input"
                  value={resetPassword?.email}
                  onChange={(e) => {
                    setError(null);
                    setSuccessMessage(null);
                    handleInputs(e);
                  }}
                />
              </div>
              <div className="action flex items-center">
                <input
                  type="submit"
                  className="btn-login button"
                  value={response.isLoading ? "Loading..." : "Reset Password"}
                />
              </div>
            </form>
          ) : step === 2 ? (
            <form
              onSubmit={pinEnterFunction}
              className="login-form flex flex-col"
            >
              {error ? <div className="alert-danger my-5">{error}</div> : null}
              {successMessage ? (
                <div className="alert-success my-5">{successMessage}</div>
              ) : null}
              <div className="desc">
                Lost your password? Please enter your username or email address.
                You will receive a link to create a new password via email.
              </div>

              <div className="input-field flex flex-col">
                <label className="field-lbl mb-2">Email</label>
                <input
                  name="email"
                  type="text"
                  required
                  placeholder="Email address *"
                  className="txt-input"
                  value={resetPassword?.email}
                  disabled={true}
                />
              </div>
              <div className="input-field flex flex-col">
                <label className="field-lbl mb-2">
                  Enter Pin you received on provided email{" "}
                </label>
                <input
                  name="pin"
                  type="number"
                  required
                  placeholder="Pin *"
                  className="txt-input"
                  value={resetPassword?.pin}
                  onChange={(e) => {
                    setError(null);
                    setSuccessMessage(null);
                    handleInputs(e);
                  }}
                />
              </div>
              <div className="action flex items-center">
                <input
                  type="submit"
                  className="btn-login button"
                  value={pinResponse.isLoading ? "Loading..." : "Confirm"}
                />
              </div>
            </form>
          ) : step === 3 ? (
            <form
              onSubmit={newPasswordFunction}
              className="login-form flex flex-col"
            >
              {error ? <div className="alert-danger my-5">{error}</div> : null}
              {successMessage ? (
                <div className="alert-success my-5">{successMessage}</div>
              ) : null}
              <div className="desc">
                Lost your password? Please enter your username or email address.
                You will receive a link to create a new password via email.
              </div>

              <div className="input-field flex flex-col">
                <label className="field-lbl mb-2">Email</label>
                <input
                  name="email"
                  type="text"
                  required
                  placeholder="Email address *"
                  className="txt-input"
                  value={resetPassword?.email}
                  disabled={true}
                />
              </div>
              <div className="input-field flex flex-col">
                <label className="field-lbl mb-2">
                  Type a new Password here!
                </label>
                <input
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  className="txt-input"
                  value={resetPassword?.password}
                  onChange={(e) => {
                    setError(null);
                    setSuccessMessage(null);
                    handleInputs(e);
                  }}
                />
              </div>
              <div className="action flex items-center">
                <input
                  type="submit"
                  className="btn-login button"
                  value={
                    passwordUpdateResponse.isLoading
                      ? "Loading..."
                      : "Update Password"
                  }
                />
              </div>
            </form>
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
};

export default ForgetPassword;
