import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const productService = createApi({
  reducerPath: "products",
  tagTypes: "product",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      productRating: builder.mutation({
        query: ({ state, id }) => {
          return {
            url: `api/products/rating/${id}`,
            method: "POST",
            body: state,
          };
        },
        invalidatesTags: ["product"],
      }),
      productQuestion: builder.mutation({
        query: ({ state, id }) => {
          return {
            url: `api/products/question/${id}`,
            method: "POST",
            body: state,
          };
        },
        invalidatesTags: ["product"],
      }),
      getProducts: builder.query({
        query: (page) => {
          return {
            url: `/api/categories`,
            method: "GET",
          };
        },
        providesTags: ["product"],
      }),
      getProductDetail: builder.query({
        query: (slug) => {
          return {
            url: `/api/products/${slug}`,
            method: "GET",
          };
        },
        providesTags: ["product"],
      }),
      getSearchedProducts: builder.query({
        query: (slug) => {
          return {
            url: `/api/searchproduct?name=${slug}`,
            method: "GET",
          };
        },
        providesTags: ["product"],
      }),
      getSliderProducts: builder.query({
        query: () => {
          return {
            url: `/api/sliderproducts`,
            method: "GET",
          };
        },
        providesTags: ["product"],
      }),
    };
  },
});
export const {
  useProductRatingMutation,
  useProductQuestionMutation,
  useGetProductQuery,
  useGetSliderProductsQuery,
  useGetSearchedProductsQuery,
  useGetProductDetailQuery,
} = productService;
export default productService;
