import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import NewLoader from "../components/NewLoader";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { setOrderData, clearCart } from "../store/reducers/globalReducer";
import { useUserOrderMutation } from "../store/services/orderService";

const PaymentForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderData } = useSelector((state) => state.globalReducer);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [order, response] = useUserOrderMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement)
    );

    // console.log("token id ", token?.id);

    if (error) {
      console.error("error on strip card", error.message);
      setPaymentError(error.message);
    } else {
      setIsButtonDisabled(true);
      await dispatch(setOrderData({ ...orderData, stripeToken: token?.id }));
      // if (token?.id) {
      //   setTimeout(() => {
      //     order(orderData);
      //   }, 3000);
      // }
    }
  };

  useEffect(() => {
    order(orderData);
  }, [orderData?.stripeToken]);

  useEffect(() => {
    if (response.isSuccess) {
      dispatch(clearCart());
      navigate("/");
    }
  }, [response.isSuccess]);

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <h2 className="title text-xl">Enter Card Information</h2>
      <div className="card-element">
        <CardElement
          options={{
            style: {
              base: {
                color: "#32325d",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
              },
            },
          }}
        />
      </div>
      {paymentError && <div className="error-message">{paymentError}</div>}
      <button
        type="submit"
        className="btn-pay  button"
        disabled={isButtonDisabled}
      >
        Pay & Place Order
      </button>
    </form>
  );
};

export default PaymentForm;
