import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchBox = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      const url = `/products/searched/${encodeURIComponent(searchTerm)}`;
      // navigate(url);
      window.location.href = url;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div className="search-box flex items-center">
      <input
        type="text"
        placeholder="Search"
        className="search-txt cleanbtn"
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <button className="btn-search button" onClick={handleSearch}>
        <i className="icon fas fa-search flex items-center justify-center text-xl"></i>
      </button>
    </div>
  );
};

export default SearchBox;
