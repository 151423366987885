import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Private from "./Private";
import Public from "./Public";

import Home from "../Pages/Home";
import Account from "../Pages/Account";
import ForgetPassword from "../Pages/Account/ForgetPassword";
import Collections from "../Pages/Collections";
import ProductDetail from "../Pages/ProductDetail";
import Checkout from "../Pages/Checkout";
import PaymentScreen from "../Pages/Checkout/PaymentScreen";
import OrderDetail from "../Pages/Account/OrderDetail";
import SearchedProducts from "../Pages/SearchedProducts";
import PrivacyPolicy from "../Pages/PrivacyPolicy";

const Routing = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route
          path="/"
          element={
            <Public>
              <Home />
            </Public>
          }
        />
        <Route
          path="/not-found"
          element={
            <Public>
              <PrivacyPolicy />
            </Public>
          }
        />
        <Route path="auth">
          <Route
            path="my-account"
            element={
              <Public>
                <Account />
              </Public>
            }
          />
          <Route
            path="lost-password"
            element={
              <Public>
                <ForgetPassword />
              </Public>
            }
          />
        </Route>
        <Route path="dashboard">
          <Route
            path="checkout"
            element={
              <Private>
                <Checkout />
              </Private>
            }
          />
          <Route
            path="payment"
            element={
              <Private>
                <PaymentScreen />
              </Private>
            }
          />
        </Route>
        <Route path="order">
          <Route
            path="detail"
            element={
              <Private>
                <OrderDetail />
              </Private>
            }
          />
        </Route>
        <Route path="collections">
          <Route
            path="shop/:id"
            element={
              <Public>
                <Collections />
              </Public>
            }
          />
        </Route>
        <Route path="products">
          <Route
            path="detail/:slug"
            element={
              <Public>
                <ProductDetail />
              </Public>
            }
          />
          <Route
            path="searched/:slug"
            element={
              <Public>
                <SearchedProducts />
              </Public>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
