import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "../../components/stripe";
import Modal from "../../components/Modal";
import NewLoader from "../../components/NewLoader";
import PaymentForm from "../../components/PaymentForm";
import { setOrderData, clearCart } from "../../store/reducers/globalReducer";
import { useUserOrderMutation } from "../../store/services/orderService";

const Checkout = () => {
  const API = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showPromoCode, setShowPromoCode] = useState(false);
  const { orderData } = useSelector((state) => state.globalReducer);

  const [selectedCountry, setSelectedCournty] = useState(null);
  const countriesOptions = [{ value: "us", label: "United State (US)" }];
  const [selectedState, setSelectedState] = useState(null);
  const statesList = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Croix", label: "Croix" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "John", label: "John" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Missouri", label: "Missouri" },
    { value: "Maryland", label: "Maryland" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Michigan", label: "Michigan" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Thomas", label: "Thomas" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Virgin Islands (VI)", label: "Virgin Islands (VI)" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];
  const handleInputs = (e) => {
    dispatch(setOrderData({ ...orderData, [e.target.name]: e.target.value }));
  };
  const handleCountry = (e) => {
    setSelectedCournty(e.value);
    dispatch(setOrderData({ ...orderData, country: e.label }));
  };
  const handleState = (e) => {
    setSelectedState(e.value);
    dispatch(setOrderData({ ...orderData, state: e.label }));
  };

  const orderFunction = (e) => {
    e.preventDefault();
    if (orderData.country === "" && orderData.state === "") {
      // console.log("Please fill in all the required fields.");
      toast.error("Select Country and State");
    } else {
      setOpen(true);
      // Show an error message or handle the case when inputs are not filled
    }
  };

  return (
    // <Wrapper>
    <div className="checkout-detail flex flex-col">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-heard-detail flex items-center">
          <img src="/images/logo.png" className="logo-img" />
        </div>
        <div className="page_block flex">
          <div className="user-info-side flex flex-col">
            <h1 className="sec-title">Information</h1>
            <form onSubmit={orderFunction} className="form-block flex flex-col">
              <div className="input-box flex flex-col">
                <input
                  type="email"
                  required
                  name="user_email"
                  value={orderData?.user_email}
                  onChange={handleInputs}
                  placeholder="Email address"
                  className="txt cleanbtn"
                />
              </div>
              <h1 className="sec-title">Shipping address</h1>
              <div className="row2">
                <div className="input-box flex flex-col">
                  <input
                    type="text"
                    required
                    placeholder="First name"
                    className="txt cleanbtn"
                    name="user_first_name"
                    value={orderData?.user_first_name}
                    onChange={handleInputs}
                  />
                </div>
                <div className="input-box flex flex-col">
                  <input
                    type="text"
                    required
                    placeholder="Last name"
                    className="txt cleanbtn"
                    name="user_last_name"
                    value={orderData?.user_last_name}
                    onChange={handleInputs}
                  />
                </div>
              </div>
              <div className="input-box flex flex-col">
                <input
                  type="text"
                  placeholder="Company name"
                  className="txt cleanbtn"
                  name="company_name"
                  value={orderData?.company_name}
                  onChange={handleInputs}
                />
              </div>
              <div className="input-box flex flex-col">
                <input
                  type="text"
                  placeholder="Street address"
                  className="txt cleanbtn"
                  name="address"
                  required
                  value={orderData?.address}
                  onChange={handleInputs}
                />
              </div>
              <div className="input-box flex flex-col">
                <input
                  type="text"
                  placeholder="Apartment, suite, unit, etc. (optional)"
                  className="txt cleanbtn"
                  name="apartment"
                  value={orderData?.apartment}
                  onChange={handleInputs}
                />
              </div>
              <div className="row2">
                <Select
                  defaultValue={selectedCountry}
                  onChange={handleCountry}
                  options={countriesOptions}
                  placeholder="Country / Region"
                  //   isClearable={isClearable}
                />
                <Select
                  defaultValue={selectedState}
                  onChange={handleState}
                  options={statesList}
                  placeholder="State"
                  className=""
                />
                <div className="input-box flex flex-col">
                  <input
                    type="text"
                    required
                    placeholder="Zip Code"
                    className="txt cleanbtn"
                    name="zipcode"
                    value={orderData?.zipcode}
                    onChange={handleInputs}
                  />
                </div>
                <div className="input-box flex flex-col">
                  <input
                    type="text"
                    required
                    placeholder="Town / City"
                    className="txt cleanbtn"
                    name="city"
                    value={orderData?.city}
                    onChange={handleInputs}
                  />
                </div>
              </div>
              <div className="input-box flex flex-col">
                <input
                  type="text"
                  required
                  placeholder="Phone"
                  className="txt cleanbtn"
                  name="user_phone"
                  value={orderData?.user_phone}
                  onChange={handleInputs}
                />
              </div>
              <div className="flex items-center justify-end">
                {/* <input
                  type="submit"
                  className="btn-next button"
                  value={response.isLoading ? "Loading..." : " Place Order"}
                /> */}
                <button
                  // to="/dashboard/payment"
                  className="btn-next button"
                  // onClick={(e) => setOpen(true)}
                >
                  Continue to Shipping
                </button>
              </div>
            </form>
          </div>
          <div className="order-info-side flex flex-col">
            <div className="flex flex-col w-full mb-6">
              {orderData?.products?.map((item, index) => (
                <div key={index} className="item-card flex">
                  <div className="img-side flex items-center justify-center relative">
                    <img src={`${API}/${item?.pro_image}`} className="p-img" />
                    <h1 className="numb absolute flex items-center justify-center text-white">
                      {item.pro_qty}
                    </h1>
                  </div>
                  <div className="item-detail flex flex-col w-full justify-center">
                    <div className="actions flex items-center justify-between gap-3">
                      <div className="prd-name flex-wrap">{item.pro_name}</div>
                      <div className="item-price">
                        ${(item.pro_price * item.pro_qty)?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {orderData?.discount === "" ? (
              <div className="promo-code flex flex-col">
                <div
                  className={`pc-lbl underline ${showPromoCode ? "hide" : ""}`}
                  onClick={(e) => setShowPromoCode(true)}
                >
                  Have a promo code? Click here.
                </div>
                <div
                  className={`code-from flex items-center ${
                    showPromoCode ? "show" : ""
                  }`}
                >
                  <input
                    type="text"
                    placeholder="Enter Promo Code"
                    className="txt cleanbtn"
                  />
                  <button className="btn-apply button">Apply</button>
                </div>
              </div>
            ) : null}
            <div className="continue-shipping-sec flex flex-col">
              <div className="info-item flex items-center justify-between">
                <h1 className="lbl">Subtotal</h1>
                <h1 className="val">$ {orderData?.price}</h1>
              </div>
              <div className="info-item flex items-center justify-between">
                <h1 className="lbl">Shipping</h1>
                <h1 className="lbl">$ {orderData?.shippingprice}</h1>
              </div>
              <div className="info-item flex items-center justify-between">
                <h1 className="lbl">Discount</h1>
                <h1 className="lbl">
                  $ {orderData?.discount ? orderData?.discount : "0"}
                </h1>
              </div>
              <div className="info-item flex items-center justify-between">
                <h1 className="lbl">Total</h1>
                <h1 className="val">$ {parseFloat(orderData?.totalprice)}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Elements stripe={stripePromise}>
          <div className="payment-page">
            <PaymentForm />
          </div>
        </Elements>
      </Modal>
    </div>
    // </Wrapper>
  );
};

export default Checkout;
